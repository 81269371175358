// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function() {};
	var methods = [
		'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
		'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
		'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
		'timeStamp', 'trace', 'warn'
	];
	var length = methods.length;
	var console = (window.console = window.console || {});

	while (length--) {
		method = methods[length];

		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
}());

// @codekit-prepend "../../../../../../node_modules/jquery/dist/jquery.js" quiet;

// ----------------------------------------------------------------------------------------- 
jQuery.noConflict();
(function($) {

	// 	mobile navigation 	 
	$('#nav-sidebar button').click(function() {
		$('#nav-sidebar ul').slideToggle(400);
		$('#nav-sidebar button').toggleClass('active');
	});

	$(window).resize(function() {
		if ($(document).width() > 480) {
			$('#nav-sidebar ul').show();
			$('#nav-sidebar button').removeClass('active');
			if ($('#nav-sidebar ul:visible')) {
				$('#nav-sidebar ul').css("display", "table-cell");
			}
		} else {
			$('#nav-sidebar ul').css("display", "block").hide();

			if ($('#nav-sidebar ul:visible')) {
				$('#nav-sidebar button').removeClass('active');
			} else {
				$('#nav-sidebar button').addClass('active');
			}
		}
	});

})(jQuery);